<template>
  <div>
    <van-cell-group title="管理">
      <van-cell title="新增登记" is-link size="large" icon="edit" to="/func/edit" v-if="whale.user.validate('/rent/index')"/>
      <van-cell title="负责确认" is-link size="large" icon="edit" to="/func/list?for=confirm"/>
    </van-cell-group>
    <van-cell-group title="查询">
      <van-cell title="我的记录" is-link size="large" icon="contact" to="/func/list?for=SUB" />
      <van-cell title="全部记录" is-link size="large" icon="search" to="/func/list" />
      <van-cell title="统计报告" is-link size="large" icon="search" to="/func/report"/>
    </van-cell-group>
  </div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped>

</style>